import {Pipe, PipeTransform} from '@angular/core';
import {localeSort} from '@app/utils/locale-sort.utils';
import {Country} from '@app/shared/components/input-countries/input-countries.component';

export class TranslatedCountriesResult {
  value: string | number;
  label: string;
  disabled?: boolean;
}

@Pipe({
    name: 'translateCountry'
})
export class TranslateCountryPipe implements PipeTransform {

    transform(value: Country[], ...args: string[]): TranslatedCountriesResult[] {
        return value.sort(localeSort((x) => x.Name)).map(it => ({
          value: it.Name,
          label: it[`Name${args[0] === 'en' ? 'En' : args[0] === 'de' ? 'De' : ''}`].toLowerCase()
            .replace(/(^|\s)\S/g, firstLetter => firstLetter.toUpperCase())
          }
        ));
    }

}
