import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {ChangePasswordComponent} from '@app/shared/components/change-password/change-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NotificationComponent} from './components/notification/notification.component';
import {RouterModule} from '@angular/router';
import {RiskValuePipe} from './pipes/risk-value.pipe';
import {CustomerTypePipe} from './pipes/customer-type.pipe';
import {VerificationTypePipe} from './pipes/verification-type.pipe';
import {PersonRealEstateArrayPipe} from './pipes/person-real-estate-array.pipe';
import {PersonProductUsageArrayPipe} from './pipes/person-product-usage-array.pipe';
import {PersonDistributionChannelArrayPipe} from './pipes/person-distribution-channel-array.pipe';
import {TypeMonthlyIncomeArrayPipe} from './pipes/type-monthly-income-array.pipe';
import {StringArrayPipe} from './pipes/string-array.pipe';
import {OrganisationDistributionChannelArrayPipe} from './pipes/organisation-distribution-channel-array.pipe';
import {OrganisationProductUsageArrayPipe} from './pipes/organisation-product-usage-array.pipe';
import {OrganisationRealEstateArrayPipe} from './pipes/organisation-real-estate-array.pipe';
import {DesignModule} from '@app/design/design.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UserProfileMenuComponent} from './components/user-profile-menu/user-profile-menu.component';
import {VeriffSessionStatusPipe} from './pipes/veriff-session-status.pipe';
import {TranslateCountryRelationPipe} from './pipes/translate-country-relation.pipe';
import {UploadDocumentComponent} from './components/upload-document/upload-document.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {ReplaceNewLinePipe} from './pipes/replace-new-line.pipe';
import {VerificationStatusPipe} from './pipes/verification-status.pipe';
import {TfaConfigurationComponent} from '@app/shared/components/tfa-configuration/tfa-configuration.component';
import {TfaSetupComponent} from '@app/shared/components/tfa-setup/tfa-setup.component';
import {SecurityConfigurationComponent} from './components/security-configuration/security-configuration.component';
import {TranslateModule} from '@ngx-translate/core';
import {MonthNamePipe} from './pipes/month-name.pipe';
import {LanguageComponent} from './components/language/language.component';
import {PortalModule} from '@angular/cdk/portal';
import {MatRadioModule} from '@angular/material/radio';
import {MatTreeModule} from '@angular/material/tree';
import {TreeDropdownComponent} from '@app/shared/components/tree-dropdown-component/tree-dropdown.component';
import {
  CreateCustomerOrganisationUboRecordComponent
} from '@app/shared/components/create-customer-organisation-ubo-record/create-customer-organisation-ubo-record.component';
import {
  ChooseStartKycEntryDialogComponent
} from '@app/shared/components/choose-start-kyc-entry-dialog/choose-start-kyc-entry-dialog.component';
import {
  AddHighRiskJurisdictionCountryComponent
} from '@app/shared/components/add-high-risk-jurisdiction-country/add-high-risk-jurisdiction-country.component';
import {NavbarLoginComponent} from './components/navbar-login/navbar-login.component';
import {ChoosePaymentMethodComponent} from '@app/shared/components/choose-payment-method/choose-payment-method.component';
import {ListRiskParametersComponent} from './components/list-risk-parameters/list-risk-parameters.component';
import {LoginSharedComponent} from './components/login-shared/login-shared.component';
import {EditOrganizationNameComponent} from './components/edit-organization-name/edit-organization-name.component';
import {InputCountriesComponent} from './components/input-countries/input-countries.component';
import {AuthenticationButtonComponent} from './components/authentication-button/authentication-button.component';
import {NoResultPageComponent} from '@app/shared/components/no-result-page/no-result-page.component';
import {SetPasswordFormComponent} from './components/set-password-form/set-password-form.component';
import {AreaTypePipe} from './pipes/area-type.pipe';
import {TypeOfIndicatorPipe} from './pipes/type-of-indicator.pipe';
import {ValidationDirective} from '@app/shared/directives/validation.directive';
import {UserLocaleConfigurationComponent} from './components/user-locale-configuration/user-locale-configuration.component';
import {TranslateCountryPipe} from './pipes/translate-country.pipe';
import {DesignFilterComponent} from './components/design-filter/design-filter.component';
import {DurationTextComponent} from '@app/shared/components/duration-text/duration-text.component';
import {ActivityStatusPipe} from './pipes/activity-status.pipe';
import {InformationDialogComponent} from './components/information-dialog/information-dialog.component';
import {AddLanguagePrefixPipe} from '@app/shared/pipes/add-language-prefix.pipe';
import {SafeLinkPipe} from '@app/shared/pipes/safe-link.pipe';
import {HeaderComponent} from '@app/landing-page/header/header.component';
import {ConvertBooleanToYesNoPipe} from './pipes/convert-boolean-to-yes-no.pipe';
import {StartKycEntryAdditionalInfoComponent} from './components/start-kyc-entry-additional-info/start-kyc-entry-additional-info.component';
import {ChooseStartKycEntriesTableComponent} from './components/choose-start-kyc-entries-table/choose-start-kyc-entries-table.component';
import {SidenavDirective} from '@app/directives/sidenav.directive';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    NotificationComponent,
    RiskValuePipe,
    CustomerTypePipe,
    VerificationTypePipe,
    PersonRealEstateArrayPipe,
    PersonProductUsageArrayPipe,
    PersonDistributionChannelArrayPipe,
    TypeMonthlyIncomeArrayPipe,
    StringArrayPipe,
    OrganisationDistributionChannelArrayPipe,
    OrganisationProductUsageArrayPipe,
    OrganisationRealEstateArrayPipe,
    UserProfileMenuComponent,
    VeriffSessionStatusPipe,
    TranslateCountryRelationPipe,
    SafeLinkPipe,
    UploadDocumentComponent,
    ConfirmationDialogComponent,
    ReplaceNewLinePipe,
    VerificationStatusPipe,
    TfaConfigurationComponent,
    TfaSetupComponent,
    SecurityConfigurationComponent,
    MonthNamePipe,
    TreeDropdownComponent,
    LanguageComponent,
    CreateCustomerOrganisationUboRecordComponent,
    ChooseStartKycEntryDialogComponent,
    AddHighRiskJurisdictionCountryComponent,
    NavbarLoginComponent,
    ChoosePaymentMethodComponent,
    ListRiskParametersComponent,
    EditOrganizationNameComponent,
    LoginSharedComponent,
    InputCountriesComponent,
    AuthenticationButtonComponent,
    DurationTextComponent,
    NoResultPageComponent,
    SetPasswordFormComponent,
    AreaTypePipe,
    TypeOfIndicatorPipe,
    ValidationDirective,
    UserLocaleConfigurationComponent,
    DesignFilterComponent,
    TranslateCountryPipe,
    ActivityStatusPipe,
    InformationDialogComponent,
    AddLanguagePrefixPipe,
    HeaderComponent,
    ConvertBooleanToYesNoPipe,
    StartKycEntryAdditionalInfoComponent,
    ChooseStartKycEntriesTableComponent,
    SidenavDirective
  ],
  imports: [
    CommonModule,
    DesignModule,
    ReactiveFormsModule,
    RouterModule,
    FlexModule,
    FontAwesomeModule,
    PortalModule,
    MatTreeModule,
    TranslateModule,
    MatRadioModule,
    FlexLayoutModule,
    FormsModule
  ],
  providers: [
    RiskValuePipe,
    CustomerTypePipe,
    VeriffSessionStatusPipe,
    TitleCasePipe,
    AddLanguagePrefixPipe
  ],
    exports: [
      RiskValuePipe,
      CustomerTypePipe,
      VerificationTypePipe,
      TypeMonthlyIncomeArrayPipe,
      PersonRealEstateArrayPipe,
      PersonProductUsageArrayPipe,
      PersonDistributionChannelArrayPipe,
      OrganisationProductUsageArrayPipe,
      OrganisationDistributionChannelArrayPipe,
      OrganisationRealEstateArrayPipe,
      StringArrayPipe,
      UserProfileMenuComponent,
      VeriffSessionStatusPipe,
      TranslateCountryRelationPipe,
      UploadDocumentComponent,
      ConfirmationDialogComponent,
      ReplaceNewLinePipe,
      VerificationStatusPipe,
      MonthNamePipe,
      TreeDropdownComponent,
      TranslateModule,
      LanguageComponent,
      MatRadioModule,
      CreateCustomerOrganisationUboRecordComponent,
      NavbarLoginComponent,
      ChoosePaymentMethodComponent,
      FlexLayoutModule,
      LoginSharedComponent,
      EditOrganizationNameComponent,
      InputCountriesComponent,
      AuthenticationButtonComponent,
      DurationTextComponent,
      DesignFilterComponent,
      NoResultPageComponent,
      SetPasswordFormComponent,
      AreaTypePipe,
      TypeOfIndicatorPipe,
      ValidationDirective,
      ActivityStatusPipe,
      AddLanguagePrefixPipe,
      SafeLinkPipe,
      HeaderComponent,
      ChooseStartKycEntriesTableComponent,
      StartKycEntryAdditionalInfoComponent,
      ConvertBooleanToYesNoPipe,
      SidenavDirective
    ]
})
export class SharedModule {
}
