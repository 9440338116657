import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {RiskCountry} from 'metricity-shared/src/interfaces/models/risk-country';
import {Country} from '@app/shared/components/input-countries/input-countries.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CountriesService {

  constructor(private http: HttpClient) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<RiskCountry[]>(environment.domain + '/countries', {}).pipe(
      map((response: RiskCountry[]) => {
        return response.map((riskCountry: RiskCountry) => {
          return {
            Name: riskCountry.country,
            NameDe: riskCountry.countryDe,
            NameEn: riskCountry.countryEn,
            alpha2: riskCountry.alpha2,
            alpha3: riskCountry.alpha3,
            currencyCode: riskCountry.currencyCode,
            id: riskCountry.id
          };
        });
      })
    );
  }
}
