<app-login-shared
  [displaySideImage]="false"
>
  <div slot="noSideImage" class="signup align-items-center">
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-sm-12 rounded">
          <div id="initFactors" class="row w-100 justify-content-center m-0">
            <div class="stepper-container signup-stepper col-sm-12 col-lg-10 rounded">
              <div class="stepper-container-top-content">
                <h3>Регистрација</h3>
              </div>
              <mat-horizontal-stepper class="transparent" [linear]="true">
                <mat-step [stepControl]="organisationFormGroup">
                  <ng-template matStepLabel>
                    {{'signup:Пополнете ги информациите за вашата организација'| translate}}</ng-template>
                  <design-input-text [placeholder]="'signup:Име'| translate" [form]="organisationFormGroup"
                                     name="name"></design-input-text>

                  <div *ngIf="organisationLevelOneTypes | async as organisationLevelOneTypes">
                    <design-input-select [label]="'risk-calculation-client-type: Тип'| translate"
                                         [form]="organisationFormGroup"
                                         name="organisationLevelOneTypeId" [options]="organisationLevelOneTypes"
                                         (change)="handleChangeLevelOne($event, organisationLevelOneTypes)"></design-input-select>
                  </div>
                  <div *ngIf="organisationLevelTwoTypes | async as organisationLevelTwoTypes">
                    <design-input-select [label]="'risk-calculation-client-type: Тип - ниво 2'| translate"
                                         [form]="organisationFormGroup"
                                         name="organisationLevelTwoTypeId" [options]="organisationLevelTwoTypes"
                                         (change)="handleChangeLevelTwo($event, organisationLevelTwoTypes)"></design-input-select>
                  </div>
                  <div *ngIf="organisationLevelThreeTypes | async as organisationLevelThreeTypes">
                    <design-input-select [label]="'risk-calculation-client-type: Тип - ниво 3'| translate"
                                         [form]="organisationFormGroup"
                                         name="organisationLevelThreeTypeId"
                                         [options]="organisationLevelThreeTypes"></design-input-select>
                  </div>
                  <design-input-text [placeholder]="'signup:Адреса'| translate" [form]="organisationFormGroup"
                                     name="address"></design-input-text>
                  <div class="signup-stepper-bottom-content m-0 row justify-content-between">
                    <div class="signup-stepper-bottom-content-item col-md-4 col-12 p-0">
                      <design-input-text [placeholder]="'signup:Град'| translate" [form]="organisationFormGroup"
                                         name="city"></design-input-text>
                    </div>
                    <div class="signup-stepper-bottom-content-item col-md-4 col-12 pr-0">
                      <design-input-text [placeholder]="'signup:Поштенски код'| translate"
                                         [form]="organisationFormGroup"
                                         name="zip"></design-input-text>
                    </div>
                    <div class="signup-stepper-bottom-content-item col-md-4 col-12 pr-0">
                      <shared-input-countries class="w-100" [label]="'signup:Држава'| translate"
                                              [form]="organisationFormGroup" name='country'></shared-input-countries>
                    </div>
                  </div>
                  <div class="signup-stepper-buttons d-flex align-items-center justify-content-center">
                    <design-button  mat-stroked-button
                                    color=""
                                    [routerLink]="'../login'"
                                    class="mr-3"
                                    text="{{'signup:Најави се'| translate}}">
                    </design-button>
                    <design-stepper-next-button [text]="'signup:Следно'| translate"
                                                direction="right"></design-stepper-next-button>
                  </div>
                </mat-step>
                <mat-step [stepControl]="managerFormGroup">
                  <ng-template matStepLabel>{{'signup:Внесете лични податоци'| translate}}</ng-template>
                  <design-input-text [placeholder]="'signup:Име'| translate" [form]="managerFormGroup"
                                     name="firstName"></design-input-text>
                  <design-input-text [placeholder]="'signup:Презиме'| translate" [form]="managerFormGroup"
                                     name="lastName"></design-input-text>
                  <design-input-text [placeholder]="'signup:Е-маил'| translate" [form]="managerFormGroup"
                                     name="email" type="email"></design-input-text>
                  <design-input-text [placeholder]="'signup:Позиција'| translate" [form]="managerFormGroup"
                                     name="position"></design-input-text>
                  <design-input-text [placeholder]="'signup:Телефон'| translate" [form]="managerFormGroup"
                                     name="phone"></design-input-text>

                  <div class="signup-stepper-buttons d-flex align-items-center justify-content-center">
                    <design-stepper-previous-button direction="left"
                                                    [text]="'signup:Назад'| translate"
                                                    class="mr-3"></design-stepper-previous-button>
                    <design-stepper-next-button [text]="'signup:Следно'| translate"
                                                direction="right"></design-stepper-next-button>
                  </div>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>{{'signup:Потврда'| translate}}</ng-template>
                  <mat-card>
                    <mat-card-header>
                      <mat-card-title>
                        {{'signup:Информации за организацијата:'| translate}}
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Име:'| translate}}</span> {{organisationFormGroup.controls['name'].value}}
                        </div>
                        <div
                          *ngIf="organisationFormGroup.controls['organisationLevelOneTypeId'].value && organisationLevelOneTypes | async as levelOneTypes"
                          class="mt-2"><span
                          class="text-muted">
                        {{'signup:Тип:'| translate}}</span> {{findLevelLabel(levelOneTypes, organisationFormGroup.controls['organisationLevelOneTypeId'].value)}}
                        </div>
                        <div
                          *ngIf="organisationFormGroup.controls['organisationLevelTwoTypeId'].value && organisationLevelTwoTypes | async as levelTwoTypes"
                          class="mt-2"><span
                          class="text-muted">
                        {{'signup:Тип - ниво 2:'| translate}}</span> {{findLevelLabel(levelTwoTypes, organisationFormGroup.controls['organisationLevelTwoTypeId'].value)}}
                        </div>
                        <div
                          *ngIf="organisationFormGroup.controls['organisationLevelThreeTypeId'].value  && organisationLevelThreeTypes | async as levelThreeTypes"
                          class="mt-2"><span
                          class="text-muted">
                        {{'signup:Тип - ниво 3:'| translate}}</span> {{findLevelLabel(levelThreeTypes, organisationFormGroup.controls['organisationLevelThreeTypeId'].value)}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Адреса:'| translate}}</span> {{organisationFormGroup.controls['address'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Град:'| translate}}</span> {{organisationFormGroup.controls['city'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Поштенски код:'| translate}}</span> {{organisationFormGroup.controls['zip'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Држава:'| translate}}</span> {{organisationFormGroup.controls['country'].value}}
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <mat-card class="mt-1">
                    <mat-card-header>
                      <mat-card-title>
                        {{'signup:Лични информации:'| translate}}
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Име:'| translate}}</span> {{managerFormGroup.controls['firstName'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Презиме:'| translate}}</span> {{managerFormGroup.controls['lastName'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Е-маил:'| translate}}</span> {{managerFormGroup.controls['email'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Позиција:'| translate}}</span> {{managerFormGroup.controls['position'].value}}
                        </div>
                        <div class="mt-2"><span
                          class="text-muted">{{'signup:Телефон:'| translate}}</span> {{managerFormGroup.controls['phone'].value}}
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <div class="mt-3">
                    <mat-checkbox [(ngModel)]="acceptedTermsAndPrivacyPolicies"></mat-checkbox>
                    {{'signup:Ги прифаќам'| translate}} <span class="clickable"
                                                              (click)="showTermsDialog()">{{'signup:правилата на користење'| translate}}</span> {{'signup:и'| translate}}
                    <span
                      class="clickable"
                      (click)="showPrivacyDialog()">{{'signup:политиката на приватност'| translate}}</span>
                  </div>

                  <div class="signup-stepper-buttons mt-3 d-flex align-items-center justify-content-center">
                    <button mat-stroked-button color="" class="mr-3"
                            matStepperPrevious>{{'signup:Назад'| translate}}</button>
                    <design-button buttonType='submit'
                                   text="{{'signup:Регистрирај се'| translate}}"
                                   [disabled]="!acceptedTermsAndPrivacyPolicies"
                                   [running]="signingUp$ | async"
                                   (clicked)="signUp()"></design-button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-login-shared>
