import {Component, Input, OnInit} from '@angular/core';
import {LanguageService} from '@app/services/language.service';
import {FormGroup} from '@angular/forms';
import {DefaultHighRiskJurisdiction} from 'metricity-shared/src/interfaces/models/default-high-risk-jurisdiction';
import {HighRiskJurisdictionCountry} from 'metricity-shared/src/interfaces/models/high-risk-jurisdiction-country';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CountriesStateService} from '@app/states/countries-state.service';
export class Country {
  Name: string;
  NameDe: string;
  NameEn: string;
  alpha2: string;
  alpha3: string;
  currencyCode: number;
  id: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'shared-input-countries',
  templateUrl: './input-countries.component.html',
  styleUrls: ['./input-countries.component.scss'],
})

export class InputCountriesComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() name: string;
  @Input() label: string;
  @Input() multiple = false;
  @Input() customClasses = '';
  @Input() countriesToBeRemoved: DefaultHighRiskJurisdiction[] | HighRiskJurisdictionCountry[] | undefined = undefined;
  countries: Observable<Country[]>;
  currentLanguage: string;

  constructor(public languageService: LanguageService,
              private countriesStateService: CountriesStateService
  ) {
  }

  ngOnInit(): void {
    this.getCountries();
    this.currentLanguage = this.languageService.getCurrentLanguage();
  }


  getCountries() {
    this.countries = this.countriesStateService.getCountries().pipe(
      map((countries: Country[]) => {
        // TODO Dragan check if this really makes sense
        if (this.countriesToBeRemoved && this.countriesToBeRemoved.length) {
          return countries.filter(
            country => !this.countriesToBeRemoved.find(
              countryToRemove => country.Name === countryToRemove.country
            )
          );
        } else {
          return countries;
        }
      })
    );
  }

}
