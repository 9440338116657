import {Injectable} from '@angular/core';
import {Country} from '../shared/components/input-countries/input-countries.component';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {CountriesService} from '../services/countries.service';

@Injectable()
export class CountriesStateService {

  private countries: Observable<Country[]>;

  constructor(countriesService: CountriesService) {
    this.countries = countriesService.getCountries().pipe(
      shareReplay(1)
    );
  }

  getCountries() {
    return this.countries;
  }
}
